import {Industries, IndustryItem} from './../definitions/data-store/industries';
import {API_URL} from './../tokens/app-tokens';
import {environment} from './../../../environments/environment';
import {HomepageResponse} from './../definitions/data-store/homepage-response';
import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, timer, of} from 'rxjs';
import {mapTo, delay, debounceTime, map, tap} from 'rxjs/operators';

import {TransferState, makeStateKey, DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {isPlatformServer, isPlatformBrowser} from '@angular/common';
import {ServiceResponse} from '../definitions/data-store/service-response';
import {BranchResponse} from '../definitions/data-store/branch-response';
import {BlogListResponse} from '../definitions/data-store/blog-list-response';
import {BlogPostResponse} from '../definitions/data-store/blog-post-response';
import {TechnologyResponse} from '../definitions/data-store/technology-response';
import {ContactResponse} from '../definitions/data-store/contact-response';
import {RealisationsResponse} from '../definitions/data-store/realisations-response';
import {AboutUsResponse} from '../definitions/data-store/about-us-response';
import {TextPageResponse} from '../definitions/data-store/text-page-response';
import {RealisationPageResponse} from '../definitions/data-store/realisation-page-response';
import {CdnResponse} from '../definitions/data-store/cdn-response';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private apiPrefix = 'alphta/v1/';

    get apiUrl(): string {
        return `${this.apiBaseUrl}${this.apiPrefix}`;
    }

    static formatIndustries(data: Industries): Industries {
        const industrieItems =
            [...data.items]
                .map((item: IndustryItem, index: number) => Object.assign({}, item, {id: index}))
                .slice(0, 6);

        const industriesData = {
            title: data.title,
            text: data.text,
            items: [...industrieItems]
        };

        return industriesData;
    }

    constructor(private http: HttpClient,
                private transferState: TransferState,
                @Inject(PLATFORM_ID) private platformId: any,
                @Inject(API_URL) private apiBaseUrl: string,
                private sanitizer: DomSanitizer
    ) {
    }

    getBlogPostBySlug(slug: string): Observable<BlogPostResponse> {
        const url = `${this.apiUrl}blog/${slug}`;
        const key = makeStateKey<BlogPostResponse>(slug);

        return this.http.get<BlogPostResponse>(url).pipe(
            map((res: BlogPostResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getTextPageData(slug: string): Observable<TextPageResponse> {
        const url = `${this.apiUrl}text-page/${slug}`;
        const key = makeStateKey<TextPageResponse>(slug);

        return this.http.get<TextPageResponse>(url).pipe(
            map((res: TextPageResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }


    getImageFromApiAsBlob(imageUrl: string): Observable<SafeUrl> {
        if (!imageUrl) {
            return of(null);
        }

        const corsUrl = imageUrl + '?https_alphta.de';

        if (isPlatformBrowser(this.platformId)) {
            return this.http.get(corsUrl, {responseType: 'blob'}).pipe(
                map(image => {
                    const blob: Blob = new Blob([image], {type: 'image/jpeg'});
                    const imageSrc = `${window.URL.createObjectURL(blob)}`;
                    return this.sanitizer.bypassSecurityTrustUrl(imageSrc);
                }));
        }

        return of(this.sanitizer.bypassSecurityTrustUrl(corsUrl));

    }


    getBlogListing(page: number, category: string = '', tag: string = ''): Observable<BlogListResponse> {
        let url = `${this.apiUrl}blog?page=${page}`;
        if (category) {
            url += `&category=${category}`;
        }

        if (tag) {
            url += `&tag=${tag}`;
        }

        // make unique key for every combination
        const key = makeStateKey<BlogListResponse>(`BLOG_LISTING_${page}${category}${tag}`);

        const dataFromServer: BlogListResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        } else if (isPlatformServer(this.platformId)) {

            return this.http.get<BlogListResponse>(url).pipe(
                map((res: BlogListResponse) => {
                    this.transferState.set(key, res);
                    return res;
                })
            );
        }

        return this.http.get<BlogListResponse>(url).pipe(
            map((res: BlogListResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }


    getConfiguration() {
        const key = makeStateKey<any>('TOP_NAVIGATION');
        const topNavigationFromServer = this.transferState.get(key, null);
        const url = `${this.apiUrl}configuration`;
        if (topNavigationFromServer) {
            return of(topNavigationFromServer);
        } else if (isPlatformServer(this.platformId)) {
            // If the key is not in the cache we are most likely running
            // on server, but we'll check anyway in case server
            // rendering is being bypassed somehow

            // Get a version number from an env variable,
            // store it in the serialized document via TransferState

            return this.http.get<any>(url).pipe(
                map((res: any) => {
                    this.transferState.set(key, res);
                    return res;
                })
            );
        }

        return this.http.get<any>(url).pipe(
            map((res: any) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getHomePageData(): Observable<HomepageResponse> {
        const url = `${this.apiUrl}page/start`;

        const key = makeStateKey<HomepageResponse>('HOMEPAGE');

        const homepageFromServer: HomepageResponse = this.transferState.get(key, null);
        if (homepageFromServer) {
            return of(homepageFromServer);
        } else if (isPlatformServer(this.platformId)) {
            // If the key is not in the cache we are most likely running
            // on server, but we'll check anyway in case server
            // rendering is being bypassed somehow

            // Get a version number from an env variable,
            // store it in the serialized document via TransferState

            return this.http.get<HomepageResponse>(url).pipe(
                map((res: HomepageResponse) => {
                    this.transferState.set(key, res);
                    return res;
                })
            );
        }

        return this.http.get<HomepageResponse>(url).pipe(
            map((res: HomepageResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getServicesDataBySlug(slug: string): Observable<ServiceResponse> {
        const url = `${this.apiUrl}service/${slug}`;

        const key = makeStateKey<ServiceResponse>(slug);

        const dataFromServer: ServiceResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        } else if (isPlatformServer(this.platformId)) {

            return this.http.get<ServiceResponse>(url).pipe(
                map((res: ServiceResponse) => {
                    this.transferState.set(key, res);
                    return res;
                })
            );
        }

        return this.http.get<ServiceResponse>(url).pipe(
            map((res: ServiceResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getBranchesByDataSlug(slug: string): Observable<BranchResponse> {
        const url = `${this.apiUrl}branch/${slug}`;

        const key = makeStateKey<BranchResponse>(slug);

        const dataFromServer: BranchResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        } else if (isPlatformServer(this.platformId)) {

            return this.http.get<BranchResponse>(url).pipe(
                map((res: BranchResponse) => {
                    this.transferState.set(key, res);
                    return res;
                })
            );
        }

        return this.http.get<BranchResponse>(url).pipe(
            map((res: BranchResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getTechnologyPageData(): Observable<TechnologyResponse> {
        const url = `${this.apiUrl}page/technologien`;
        const key = makeStateKey<TechnologyResponse>('TECHNOLOGIES');
        const dataFromServer: TechnologyResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<TechnologyResponse>(url).pipe(
            map((res: TechnologyResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getCdnPageData(): Observable<CdnResponse> {
        const url = `${this.apiUrl}page/wordpress-cdn`;
        const key = makeStateKey<CdnResponse>('CDN_LANDING');
        const dataFromServer: CdnResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<CdnResponse>(url).pipe(
            map((res: CdnResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getRealisationsData(): Observable<RealisationsResponse> {
        const url = `${this.apiUrl}realisations`;
        const key = makeStateKey<RealisationsResponse>('REALISATIONS');
        const dataFromServer: RealisationsResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<RealisationsResponse>(url).pipe(
            map((res: RealisationsResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getRealisationData(slug): Observable<RealisationPageResponse> {
        const url = `${this.apiUrl}realisations/${slug}`;
        const key = makeStateKey<RealisationPageResponse>(`REALISATIONS_${slug}`);
        const dataFromServer: RealisationPageResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<RealisationPageResponse>(url).pipe(
            map((res: RealisationPageResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getContactPageData(): Observable<ContactResponse> {
        const url = `${this.apiUrl}page/kontakt`;
        const key = makeStateKey<ContactResponse>('CONTACT');
        const dataFromServer: ContactResponse = this.transferState.get(key, null);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<ContactResponse>(url).pipe(
            map((res: ContactResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }

    getAboutUsPageData(): Observable<AboutUsResponse> {
        const url = `${this.apiUrl}page/agentur`;
        const key = makeStateKey<AboutUsResponse>('ABOUTUS');
        const dataFromServer: AboutUsResponse = this.transferState.get(key, null);
        console.log('DATA FROM SERVER', dataFromServer);
        if (dataFromServer) {
            return of(dataFromServer);
        }
        return this.http.get<AboutUsResponse>(url).pipe(
            map((res: AboutUsResponse) => {
                this.transferState.set(key, res);
                return res;
            })
        );
    }
}
