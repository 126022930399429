<section class="quote">
  <div class="container">
      <div class="quote__inner">
          <div class="quote__inner__title wow fadeIn">{{data?.text}}
          </div>
          <div class="quote__inner__author wow fadeIn">{{data?.author}}</div>
      </div>
      <div class="quote__decoration">
          <img src="assets/img/falka.png" alt="">
      </div>
  </div>
</section>
