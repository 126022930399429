import { NgModule } from '@angular/core';
import { QuoteComponent } from './quote.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [QuoteComponent],
    imports: [
        CommonModule
    ],
    exports: [
        QuoteComponent
    ]
})
export class QuoteModule { }
