<ul [ngStyle]="{'display': 'none'}" class="header-menu" *ngIf="navItemList">
  <li *ngFor="let navItem of navItemList" class="header-menu__item">
    <a
      class="header-menu__link"
      (click)="onMenuItemClick($event, navItem)"
      [ngClass]="getHeaderMenuLinkClassList(navItem)"
      >{{ navItem.name }}
      <img
        *ngIf="showSubNav(navItem)"
        class="header-menu__link__icon"
        src="assets/img/down-arrow.svg"
        width="20"
      />
    </a>
    <div
      *ngIf="showSubNav(navItem)"
      class="header-menu__sub"
      [ngClass]="getSubMenuClass(navItem)"
    >
      <div class="container">
        <div class="decoration-holder">
          <div class="header-menu__sub__decoration">{{navItem.name}}</div>
        </div>
        <div class="header-menu__sub__items">
          <ul class="header-menu__sub__items__column">
            <li
              *ngFor="
                let item of subNavigations[navItem.url].col1;
                let i = index
              "
              [attr.data-counter]="getDataCounter(i)"
              class="header-menu__sub__items__item"
            >
              <a
                (click)="onClicklink($event, item)"
                class="header-menu__sub__items__item__link"
                >{{ item.name }}</a
              >
            </li>
          </ul>
          <ul class="header-menu__sub__items__column">
            <li
              *ngFor="
                let item of subNavigations[navItem.url].col2;
                let i = index
              "
              [attr.data-counter]="getDataCounter(i + 4)"
              class="header-menu__sub__items__item"
            >
              <a
                (click)="onClicklink($event, item)"
                class="header-menu__sub__items__item__link"
                >{{ item.name }}</a
              >
            </li>
          </ul>

          <ul class="header-menu__sub__items__column">
            <li
              *ngFor="
                let item of subNavigations[navItem.url].col3;
                let i = index
              "
              [attr.data-counter]="getDataCounter(i + 8)"
              class="header-menu__sub__items__item"
            >
              <a
                (click)="onClicklink($event, item)"
                class="header-menu__sub__items__item__link"
                >{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <button class="header-menu__close">
        <img alt="" src="assets/img/close.svg" />
      </button>
    </div>
  </li>
</ul>
