import { BehaviorSubject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventBusService {
  private openOfferBoxSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  openOfferBox$: Observable<boolean> = this.openOfferBoxSubject.asObservable();

  private isUnderTheFoldSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  isUnderTheFold$: Observable<boolean> = this.isUnderTheFoldSubject.asObservable();

  private isWowInitializedSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  isWowInitialized$: Observable<boolean> = this.isWowInitializedSubject.asObservable();


  constructor() { }

  openOfferBox(params?: any): void {
    this.openOfferBoxSubject.next(params);
  }

  updateIsUnderTheFold(data: boolean): void {
    this.isUnderTheFoldSubject.next(data);
  }

  updateIsWowInitialized(state: boolean) {
    this.isWowInitializedSubject.next(state);
  }
}
