<section class="hero">
    <div class="container">
        <div *ngIf="data" class="hero__text">
            <h1 class="hero__text__lead" [innerHTML]="data?.lead_text"></h1>
            <ul class="hero__text__fields">
                <li *ngFor="let tag of data?.tags" class="hero__text__fields__field"> {{tag.text}}</li>
            </ul>
            <div *ngIf="isMobile" class="hero__image ">
                <div class="hero__image__background">
                    <img width="1030" src="assets/img/bg_0.svg" alt="Digitalagentur in Berlin">
                    <img class="deco2" src="assets/img/circle.svg" alt="">
                    <img src="assets/img/falka.png" alt="Digitalagentur in Berlin">
                </div>
                <div class="hero__image__front">
                    <img [src]="data.img1.url" [alt]="data.img1.alt" class="hero__image__front__first">
                    <img [src]="data.img2.url" [alt]="data.img2.alt" class="hero__image__front__second">
                </div>
            </div>
            <a href="#" (click)="requestOffer()" class="button">Jetzt anfragen <img src="assets/img/arrow-button.svg" alt=""></a>
        </div>
        <div *ngIf="data && !isMobile" class="hero__image ">
            <div class="hero__image__background">
                <img src="assets/img/bg_0.svg" alt="Digitalagentur in Berlin">
                <img *ngIf="isBrowser" src="assets/img/bg_1.svg" alt="Webdesign Berlin">
                <img class="deco2" src="assets/img/circle.svg" alt="">
                <img src="assets/img/falka.png" alt="Digitalagentur in Berlin">
            </div>
            <div class="hero__image__front">
                <img [src]="data.img1.url" [alt]="data.img1.alt" class="hero__image__front__first">
                <img [src]="data.img2.url" [alt]="data.img2.alt" class="hero__image__front__second">
            </div>
        </div>
    </div>
</section>

