<ngx-loading-bar [color]="'#0fa'"></ngx-loading-bar>
<app-header
        *ngIf="!isLandingPage"
  [closeSubNav]="clickOutside$ | async"
  [navItemList]="topNavigation$ | async"
></app-header>

<ng-container #offerPopupContainer> </ng-container>

<div class="not-ready" *ngIf="loading$ | async">
  <div class="loading">
    <div class="spinner">
      <div class="double-bounce1"></div>
      <div class="double-bounce2"></div>
    </div>
  </div>
</div>

<app-hero
  *ngIf="isHomepage"
  class="wow slideInLeft"
  [data]="heroData$ | async"
  [isMobile]="isMobile"
  (load)="onHeroLoad()"
></app-hero>
<router-outlet (activate)="onActivate($event)"></router-outlet>
<ng-container #footerContainer></ng-container>
<app-footer-ssr *ngIf="isServer" [data]="footerData"></app-footer-ssr>
