import { OnDestroy, HostListener, OnInit, AfterViewChecked, Directive } from '@angular/core';
import { Subject } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class BasePage implements OnDestroy {
    protected destroyed$: Subject<boolean> = new Subject();
    protected innerWidth: number;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (isPlatformBrowser(this.platformId)) {
            const w = event.target.innerWidth;
            this.innerWidth = w;
        } else {
            this.innerWidth = 1440;
        }
    }

    get isMobile(): boolean {
        return this.innerWidth < 768;
    }

    get currentInnerWidth(): number {
        return this.innerWidth;
    }

    constructor(protected platformId) {
        if (isPlatformBrowser(this.platformId)) {
            const w = window.innerWidth;
            this.innerWidth = w;
        }
    }

    ngOnDestroy() {
        this.destroyed$.next(true);
        this.destroyed$.complete();
    }
}
