import { ConfigurationResponseFooterData } from './../../definitions/data-access/configuration';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-ssr',
  templateUrl: './footer-ssr.component.html',
  styleUrls: ['./footer-ssr.component.scss']
})
export class FooterSsrComponent implements OnInit {
  @Input() data: ConfigurationResponseFooterData;
  constructor() { }

  ngOnInit(): void {}

}
