<app-our-offer [data]="offerData"></app-our-offer>
<app-quote [data]="quoteData"></app-quote>
<!--
<app-our-team [data]="agencyIntro"></app-our-team>
<app-numbers [data]="numbersData"></app-numbers>
<app-industries *appSsrNoRender [data]="industriesData"></app-industries>
<app-about-our-offer [data]="offerIntro"></app-about-our-offer>
<app-our-projects *appSsrNoRender [data]="portfolioData"></app-our-projects>
<app-about-our-projects [data]="secondQuote"></app-about-our-projects>
<app-our-philosophy [data]="philosophyData"></app-our-philosophy>
<app-technology [data]="technologiesData"></app-technology>
<app-blog-listing [data]="blogIntroData"></app-blog-listing>
<app-references [data]="userStoriesData" [isMobile]="isMobile"></app-references>
<app-quick-contact></app-quick-contact> -->

<ng-container #componentsContainer></ng-container>

<app-home-content-ssr
  [agencyIntro]="lazyComponentsContents.agencyIntro"
  [numbersData]="lazyComponentsContents.numbersData"
  [industriesData]="lazyComponentsContents.industriesData"
  [aboutOurOffer]="lazyComponentsContents.offerIntro"
  [portfolioData]="lazyComponentsContents.portfolioData"
  [secondQuote]="lazyComponentsContents.secondQuote"
  [philosophyData]="lazyComponentsContents.philosophyData"
  [technologiesData]="lazyComponentsContents.technologiesData"
  [blogIntroData]="lazyComponentsContents.blogIntroData"
  [userStoriesData]="lazyComponentsContents.userStoriesData"

  *ngIf="isServer"
></app-home-content-ssr>
