import { Component, Input, OnInit, NgModule, ChangeDetectionStrategy } from '@angular/core';
import { Quote } from '../../definitions/data-store/quote';
import { CommonModule } from '@angular/common';

@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuoteComponent implements OnInit {
    @Input() data: Quote;

    constructor() { }

    ngOnInit() {
    }
}
