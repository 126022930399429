<header
  #header
  class="header"
  [ngClass]="{ 'header--sticky': isSticky, 'header--hidden': isHidden }"
>
  <div class="header__contents">
    <div class="header__logo">
      <a [routerLink]="'/'">
        <img
          width="208"
          src="assets/img/logo.svg"
          alt="Alphta Digital Lab"
          class="header__logo__image"
        />
      </a>
    </div>
    <app-header-menu
      class="header__menu"
      [closeSubNav]="closeSubNav"
      [navItemList]="navItemList"
      [isSticky]="isSticky"
      *ngIf="shouldRender"
    ></app-header-menu>
    <div class="header__button">
      <a (click)="toggleOffer($event)" href="" class="button button--empty"
        >Angebot einholen<img src="assets/img/arrow-button.svg" width="20" alt=""
      /></a>
    </div>
    <a href="tel:+49 151 652 12596" class="header__phone"><svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"> <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g id="Artboard" transform="translate(-493.000000, -40.000000)" fill="#00FFA7" fill-rule="nonzero"> <g id="phone" transform="translate(493.000000, 40.000000)"> <path d="M11.9772923,10.970037 L10.0502269,12.869466 L5.13018301,7.94829337 L7.0291763,6.02078593 C7.26406121,5.78179546 7.33125926,5.42452003 7.19926005,5.1164926 L5.22183008,0.500054471 C5.06026522,0.123791755 4.64743633,-0.0772229653 4.2516922,0.0276747555 L0.618835453,0.987299282 C0.244980081,1.08405305 -0.0118873223,1.42707886 0.000424157174,1.81313795 C0.2314723,6.03638475 1.9870507,10.0322395 4.94110933,13.058583 C7.96772526,16.0137466 11.963953,17.7694852 16.1872779,17.9995757 C16.5732484,18.01189 16.9161956,17.7549637 17.0129272,17.3810226 L17.9723316,13.7473325 C18.0772053,13.3514976 17.8762366,12.938574 17.5000602,12.776972 L12.8846809,10.7990884 C12.5756533,10.6657712 12.2166548,10.7334052 11.9772923,10.970037 Z" id="Path"></path> </g> </g> </g> </svg></a>
    <app-mobile-menu-toggle
      *ngIf="shouldRender"
      (mobileMenuToggle)="mobileMenuToggle($event)"
      [isMobileMenuOpen]="isMobileMenuOpen"
      class="header__toggle"
    ></app-mobile-menu-toggle>
  </div>
</header>
<ng-container #mobileMenuContainer></ng-container>
