import { DOCUMENT } from '@angular/common';
import { Injectable, Renderer2, Inject, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptsLoaderService {
  renderer: Renderer2;

  constructor(private renderer2Fact: RendererFactory2, @Inject(DOCUMENT) private document) {
    this.renderer = renderer2Fact.createRenderer(null, null);
  }

  loadLoadAsync(scriptUrl: string, cb: any) {

    const s = this.renderer.createElement('script');
    s.onload = cb;
    s.type = 'text/javascript';
    s.src = scriptUrl; // Defines someGlobalObject
    s.text = ``;
    this.renderer.appendChild(this.document.body, s);

  }

}
