import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OurOfferComponent } from './our-offer.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [OurOfferComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  exports: [OurOfferComponent]
})
export class OurOfferModule { }
