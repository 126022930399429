import { Observable, of } from 'rxjs';
import { Hero } from './../definitions/shared-components/hero';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HomepageHeroService {

  constructor() { }

  getMockHeroData(): Observable<Hero> {
    return of({
      // tslint:disable-next-line:max-line-length
      lead_text: 'Als eine <strong> Digitalagentur in Berlin </strong> helfen wir Unternehmen wie Ihr <strong> digitale Plattformen </strong> im Rekordtempo aufzubauen.',
      tags: [
        {
          text: 'UX',
          link: '/service/webdesign-lab'
        },
        {
          text: 'Webdesign',
          link: '/service/webdesign-lab'
        },
        {
          text: 'MVP-Entwicklung',
          link: '/service/startups-und-mvp-entwicklung'
        },
        {
          text: 'Drupal',
          link: '/service/drupal-lab'
        },
        {
          text: 'AWS',
          link: '/service/aws-cloud-computing'
        }
      ],
      img1: {
        alt: 'Digitale Plattform - Sharethecoach.de',
        url: 'https://cdn2.alphta.de/wp-content/uploads/2021/03/05183314/sharethecoach-iphone.png'
      },
      img2: {
        alt: 'Digitale Plattform - Sharethecoach.de',
        url: 'https://cdn2.alphta.de/wp-content/uploads/2021/03/05183318/sharethecouch-alphta-ipad.png'
      }
    });
  }
}
