import { ConfigurationResponse } from './../definitions/data-access/configuration';
import { ApiService } from './../data-access/api.service';
import { environment } from './../../../environments/environment';
import { NavItem } from './../definitions/shared-components/nav-item';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TopNavigation } from '../models';


@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  constructor(private apiService: ApiService, private httpCient: HttpClient, private router: Router) { }

  getTopNavigation(): Observable<NavItem[]> {
    return this.apiService.getConfiguration().pipe(
      map((configurationResponse: ConfigurationResponse) => {
        return new TopNavigation(configurationResponse.top_menu).items;
      })
    );
  }

  getConfiguration(): Observable<ConfigurationResponse> {
    return this.apiService.getConfiguration();
  }
}
