<section class="our-offer">
  <div class="container">
    <h3 class="section-lead__title ">{{ data?.title }}</h3>
    <p class="section-lead__text  " [innerHTML]="data?.subtitle"></p>
    <div *ngIf="data?.items" class="our-offer__items">
      <article
        *ngFor="let item of data?.items; trackBy: title"
        class="our-offer-item"
      >
        <div class="our-offer-item__holder  ">
          <div class="our-offer-item__before">
            <a [routerLink]="item.link">
              <img
                [src]="item.image.url"
                [alt]="item.image.alt"
                class="our-offer-item__before__icon"
              />
              <h3 class="our-offer-item__before__title">{{ item.title }}</h3>
            </a>
          </div>
          <div class="our-offer-item__after">
            <h3 class="our-offer-item__after__title">{{ item.title }}</h3>
            <p class="our-offer-item__after__text" *ngIf="item.technologies">
              <a
                *ngFor="let technology of item?.technologies; trackBy: title"
                [routerLink]="item.link"
                [fragment]="technology.url"
              >
                {{ technology.name }}<br />
              </a>
            </p>
            <a [routerLink]="item.link" class="our-offer-item__after__more"
              >mehr</a
            >
          </div>
        </div>
      </article>
    </div>

    <div class="our-offer__button">
      <a (click)="openOfferBox($event)" href="" class="our-offer__button__inner"
        >{{ data?.button }}
        <img
          src="assets/img/arrow-button.svg"
          alt=""
          class="our-offer__button__inner__icon"
      /></a>
    </div>
  </div>
</section>
