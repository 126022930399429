import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    OnChanges,
    SimpleChanges,
    ChangeDetectorRef,
    AfterViewInit,
    ChangeDetectionStrategy
} from '@angular/core';
import { NavItem } from '../../../../shared/definitions/shared-components/nav-item';
import { DeviceDetectorService } from '../../../../shared/utils/device-detector.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header-menu',
    templateUrl: './header-menu.component.html',
    styleUrls: ['./header-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderMenuComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() navItemList: NavItem[];
    @Input() closeSubNav: boolean;
    @Input() isSticky: boolean;

    activeNavItem: string;

    subNavVisible = false;

    subNavigations = {};

    private os: string;


    constructor(private changeDetector: ChangeDetectorRef, private deviceService: DeviceDetectorService, private router: Router) {
    }

    ngOnInit() { }

    ngOnChanges(changes: SimpleChanges) {
        if (changes && typeof (changes.closeSubNav) !== 'undefined' && changes.closeSubNav.currentValue !== null) {
            this.subNavVisible = false;
            this.activeNavItem = null;
        }
    }

    ngAfterViewInit() {
        this.changeDetector.detectChanges();
    }

    showSubNav(navItem: NavItem): boolean {
        // return this.getChildren(navItem) && navItem.name === this.activeNavItem && this.subNavVisible;
        return this.getChildren(navItem);
    }

    onMenuItemClick(event, navItem: NavItem) {
        event.preventDefault();
        this.activeNavItem = this.activeNavItem === navItem.name ? null : navItem.name;

        console.log('acitveItem', this.activeNavItem, navItem);

        if (this.activeNavItem === navItem.name) {
            this.subNavVisible = true;
        }

        if (!this.hasChildren(navItem)) {
            this.router.navigate([navItem.url]);
        }
    }

    getDataCounter(index: number): string {
        const newIndex = index + 1 + '';
        return newIndex.length > 1 ? newIndex : 0 + '' + newIndex;
    }

    onClicklink(event, item: NavItem) {
        event.preventDefault();
        this.activeNavItem = null;
        this.subNavVisible = false;
        this.router.navigate([item.url]);
    }

    isMenuHoveredOver(item: NavItem) {
        let val;
        val = this.activeNavItem === item.name;
        return val;
    }

    getSubMenuClass(item: NavItem): string[] {
        return [this.activeNavItem === item.name ? 'header-menu__sub--active' : ''];
    }

    hasChildren(item: NavItem) {
        return item.children && item.children.length > 0;
    }

    getHeaderMenuLinkClassList(item: NavItem): string[] {
        const headerMenuLinkClassList = [];
        if (this.deviceService.isIos || this.deviceService.isMac) {
            headerMenuLinkClassList.push('header-menu__link--ios');
        }
        if (this.isSticky) {
            headerMenuLinkClassList.push('header-menu__link--sticky');
        }
        if (this.hasChildren(item)) {
            headerMenuLinkClassList.push('header-menu__link--children');
        }
        if (this.isMenuHoveredOver(item)) {
            headerMenuLinkClassList.push('header-menu__link--active');
        }
        return headerMenuLinkClassList;
    }

    getChildren(item: NavItem) {
        if (this.subNavigations[item.url]) {
            return this.subNavigations[item.url];
        }

        if (item.children && item.children.length > 0) {

            const subNavigationCols = item.children.reduce((prev, next, index) => {
                if (index < 5) {
                    prev.col1.push(next);
                    return prev;
                }

                if (index < 10) {
                    prev.col2.push(next);
                    return prev;
                }

                prev.col3.push(next);
                return prev;

            }, {
                col1: [],
                col2: [],
                col3: []
            } as any);

            this.subNavigations[item.url] = { ...subNavigationCols };
            console.log(item, this.subNavigations);
            return this.subNavigations[item.url];
        }
    }
}
