import { isPlatformServer } from '@angular/common';
import { Directive, OnInit, ViewContainerRef, TemplateRef, PLATFORM_ID, Inject } from '@angular/core';

@Directive({
  selector: '[appSsrNoRender]'
})
export class SsrNoRenderDirective implements OnInit {

  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    @Inject(PLATFORM_ID) private platformId) { }

  ngOnInit() {
    if (isPlatformServer(this.platformId)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
