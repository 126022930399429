import {HomeComponent} from './pages/home/home/home.component';
import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

// root route needs to be plain without resolver for ssr to work
const routes: Routes = [
    {
        path: '',
        component: HomeComponent,
        pathMatch: 'full'
    },
    {
        path: 'technologien',
        loadChildren: () => import('./pages/technologies/technologies.module').then(m => m.TechnologiesModule)
    },
    {
        path: 'referenzen',
        loadChildren: () => import('./pages/realisations/realisations.module').then(m => m.RealisationsModule)
    },
    {
        path: 'agentur',
        loadChildren: () => import('./pages/about-us/about-us.module').then(m => m.AboutUsModule)
    },
    {
        path: 'kontakt',
        loadChildren: () => import('./pages/contact/contact.module').then(m => m.ContactModule)
    },
    {
        path: 'wordpress-lab',
        loadChildren: () => import('./pages/wordpress-lab/wordpress-lab.module').then(m => m.WordpressLabModule)
    },
    {
        path: 'service/:slug',
        loadChildren: () => import('./pages/single-service-wrapper/single-service-wrapper.module').then(m => m.SingleServiceWrapperModule)
    },
    {
        path: 'branchen',
        loadChildren: () => import('./pages/single-branch/single-branch.module').then(m => m.SingleBranchModule)
    },
    {
        path: 'transformation-blog',
        loadChildren: () => import('./pages/blog-list/blog-list.module').then(m => m.BlogListModule)
    },
    {
        path: 'p',
        loadChildren: () => import('./pages/blog-post/blog-post.module').then(m => m.BlogPostModule)
    },
    {
        path: 'wordpress-cdn',
        loadChildren: () => import('./pages/landings/cdn/cdn.module').then(m => m.CdnModule)
    },
    {
        path: '**',
        loadChildren: () => import('./pages/text-page/text-page.module').then(m => m.TextPageModule)
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
