import { SecondQuote } from './../../definitions/data-store/second-quote';
import { UserStories } from './../../definitions/data-store/user-stories';
import { BlogIntro } from './../../definitions/data-store/BlogIntro';
import { Technologies } from './../../definitions/data-store/technologies';
import { Philosophy } from './../../definitions/data-store/philosophy';
import { Portfolio } from './../../definitions/data-store/homepage-portfolio';
import { OfferIntro } from './../../definitions/data-store/offer-intro';
import { Industries } from './../../definitions/data-store/industries';
import { Numbers } from './../../definitions/data-store/numbers';
import { AgencyIntro } from './../../definitions/data-store/agencyIntro';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-home-content-ssr',
  template: `
  <h2>HomeContent</h2>
  <section>
    <div>
      <div>
        <h3>{{ agencyIntro?.title }}</h3>
        <p>{{ agencyIntro?.text }}</p>
        <p>{{ agencyIntro?.text_2 }}</p>
        <a
          >{{ agencyIntro?.button_text }}
          <img src="assets/img/arrow-button.svg" alt=""
        /></a>
      </div>
      <div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div *ngFor="let number of numbersData?.numbers">
        <div>{{ number.number }}</div>
        <div>
          <div>{{ number.title }}</div>
          <div>{{ number.text }}</div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <h3>{{ industriesData?.title }}</h3>
      <p>{{ industriesData?.text }}</p>
      <div>
        <ul class="branches-control">
          <ng-container *ngFor="let item of industriesData?.items">
            <li>
              <a>{{ item.title }}</a>
              <div>
                {{ item.text }} <br />
                <a>Mehr erfahren</a>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div>
        <img [src]="offerIntro?.image.url" [alt]="offerIntro?.image.alt" />
      </div>
      <div>
        <h3>{{ offerIntro?.title }}</h3>
        <div>{{ offerIntro?.text }}</div>
        <a>{{ offerIntro?.button_text }}</a>
      </div>
    </div>
  </section>
  <section class="our-projects">
    <div class="container">
      <h3>{{ portfolioData?.title }}</h3>
      <div class="our-projects__list">
        <div class="portfolio-item" *ngFor="let item of portfolioData?.items">
          <div>
            <img
              [defaultImage]="defaultImage"
              [lazyLoad]="item.image.url"
              [alt]="item.image.alt"
            />
            <div>
              <div>{{ item.details }}</div>
              <div>
                <div>{{ item.title }}</div>
                <div>{{ item.description }}</div>
                <a rel="noreferrer">{{ item.button_text }}</a>
              </div>
              <ul class="portfolio-item__image__hover__tags">
                <li *ngFor="let tag of item.tags">
                  {{ tag }}
                </li>
              </ul>
            </div>
          </div>
          <div class="portfolio-item__description">
            <div>{{ item.title }}</div>
            <div>{{ item.details }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <div>{{ secondQuote?.text }}</div>
      <div>
        <a href="secondQuote?.button_link" class="button">{{
          secondQuote?.button_text
        }}</a>
      </div>
    </div>
  </section>
  <div>
    <article *ngFor="let item of philosophyData?.items">
      <h4>{{ item.title }}</h4>
      <p>{{ item.text }}</p>
    </article>
  </div>
  <section class="technology">
    <div>
      <h3>{{ technologiesData?.title }}</h3>
      <p>{{ technologiesData?.text }}</p>
      <div class="technology__items ">
        <div
          *ngFor="let technologyItem of technologiesData?.items"
          class="technology-item  wow fadeIn"
        >
          <div>
            <h4>{{ technologyItem.title }}</h4>
          </div>
          <ul class="technology-item__list">
            <li *ngFor="let technologyListItem of technologyItem.items">
              {{ technologyListItem.text }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="container">
      <h3>{{ blogIntroData?.title }}</h3>
      <p>{{ blogIntroData?.text }}</p>
      <div class="blog__listing">
        <div *ngFor="let item of blogIntroData?.data">
          <article>
            <ul class="blog-item__categories" *ngIf="item.categories.length">
              <li *ngFor="let category of item.categories">
                <a
                  [routerLink]="['/transformation-blog/kategorie', category.slug]"
                >
                  {{ category.name }}</a
                >
              </li>
            </ul>
            <a>
              <img [lazyLoad]="item.image.url" [alt]="item.image.alt" />
            </a>
            <div>
              <div>
                <a> {{ item.title }}</a>
              </div>
              <div [innerHtml]="item.excerpt"></div>
              <div>
                <div>
                  <img [lazyLoad]="item.avatar" [alt]="item.author" />
                  <span>{{ item.author }}</span>
                </div>
                <div>
                  <span>{{ item.date }}</span>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div>
        <a [href]="'/transformation-blog'" class="button">Mehr erfahren </a>
      </div>
    </div>
  </section>
  <section>
  <div>{{userStoriesData?.title}}</div>
  <div class="container">
      <h3>{{userStoriesData?.title}}</h3>
        <div *ngFor="let item of userStoriesData?.user_stories">
            <div class="reference">
                <div>
                    <p>{{item.text}}</p>
                    <div >{{item.title}}</div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="contact-box">
        <div> Lassen Sie uns über Ihr Projekt sprechen!</div>
        <div>
            <div>
                <div>Lukasz Gawrys</div>
                <div>Inhaber</div>
                <div>+49 151 652 12596 <br> lukasz@alphta.de </div>
            </div>
      </div>
</section>
  `,
})
export class HomeContentSsrComponent implements OnInit {

  @Input() agencyIntro: AgencyIntro;
  @Input() numbersData: Numbers;
  @Input() industriesData: Industries;
  @Input() offerIntro: OfferIntro;
  @Input() portfolioData: Portfolio;
  @Input() philosophyData: Philosophy;
  @Input() secondQuote: SecondQuote;
  @Input() technologiesData: Technologies;
  @Input() blogIntroData: BlogIntro;
  @Input() userStoriesData: UserStories;

  constructor() { }

  ngOnInit(): void {

  }

}
