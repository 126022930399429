import { NavItem } from './../../../definitions/shared-components/nav-item';
import {
    Component,
    OnInit,
    Input,
    ViewEncapsulation,
    Output,
    EventEmitter,
    HostListener,
    ViewChild,
    ElementRef,
    AfterViewInit,
    PLATFORM_ID,
    Inject, Renderer2, ViewContainerRef, ComponentFactoryResolver, Injector, ChangeDetectionStrategy, ChangeDetectorRef
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { GlobalEventBusService } from '../../../data-store/global-event-bus.service';
import { MobileMenuComponent as MobileMenuComponentDef } from '../mobile-menu/mobile-menu.component';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit, AfterViewInit {
    private offerBoxVisible = false;
    private mobileMenuRendered = false;
    isSticky = false;
    isHidden = false;
    private elementHeight: number;
    isMobileMenuOpen: boolean;
    mobileMenuInstance: MobileMenuComponentDef;

    @ViewChild('mobileMenuContainer', { read: ViewContainerRef, static: true }) mobileMenuContainer;

    get shouldRender() {
        return isPlatformBrowser(this.platformId);
    }

    @Input() navItemList: NavItem[];
    @Input() closeSubNav: boolean;

    @ViewChild('header') headerElement: ElementRef;

    @Output() toggleOfferBox: EventEmitter<boolean> = new EventEmitter();

    @HostListener('window:scroll', ['$event']) checkStickyMenu() {
        if (isPlatformBrowser(this.platformId)) {
            const windowScroll = window.pageYOffset;

            if (windowScroll > this.elementHeight && windowScroll < window.innerHeight) {
                this.isHidden = true;
            } else if (windowScroll > window.innerHeight) {
                this.isSticky = true;
                this.isHidden = false;
                this.globalEventBus.updateIsUnderTheFold(true);
            } else {
                this.isSticky = false;
                this.isHidden = false;
                this.globalEventBus.updateIsUnderTheFold(false);
            }
        }
    }

    async mobileMenuToggle(isMobileMenuOpen) {
        let comp;
        this.isMobileMenuOpen = isMobileMenuOpen;
        if (this.isMobileMenuOpen) {
            if (!this.mobileMenuInstance) {
                const { MobileMenuComponent } = await import('./../mobile-menu/mobile-menu.component');

                const mobileMenuCompFactory = this.cfr.resolveComponentFactory(MobileMenuComponent);

                comp = this.mobileMenuContainer.createComponent(
                    mobileMenuCompFactory, null, this.injector
                );

                this.mobileMenuInstance = comp.instance;

                this.mobileMenuInstance.isMobileMenuOpen = false;
                // tslint:disable-next-line:no-string-literal
                this.mobileMenuInstance.navItemList = this.navItemList;
                this.cdr.detectChanges();

                if (isPlatformBrowser(this.platformId)) {
                    setTimeout(() => {
                        this.mobileMenuInstance.isMobileMenuOpen = this.isMobileMenuOpen;
                        this.cdr.detectChanges();
                    }, 100);
                }

                this.mobileMenuInstance.mobileMenuToggle.subscribe((ev) => {
                    this.mobileMenuToggle(ev);
                });

            } else {
                this.mobileMenuInstance.isMobileMenuOpen = this.isMobileMenuOpen;
            }
            this.renderer.addClass(this.document.body, 'menu-open');
            this.renderer.addClass(this.document.documentElement, 'menu-open');
        } else {
            this.mobileMenuInstance.isMobileMenuOpen = this.isMobileMenuOpen;
            this.renderer.removeClass(this.document.body, 'menu-open');
            this.renderer.removeClass(this.document.documentElement, 'menu-open');
        }
    }

    ngAfterViewInit() {
        this.elementHeight = this.headerElement.nativeElement.offsetHeight;
    }

    constructor(
        @Inject(PLATFORM_ID) private platformId: any,
        private globalEventBus: GlobalEventBusService,
        private renderer: Renderer2,
        @Inject(DOCUMENT) private document: Document,
        private cdr: ChangeDetectorRef,
        private cfr: ComponentFactoryResolver,
        private injector: Injector) {
    }

    ngOnInit() {
    }

    toggleOffer(event) {
        event.preventDefault();
        this.offerBoxVisible = true;
        this.globalEventBus.openOfferBox({ step: 0 });
    }

}
