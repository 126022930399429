import { NavItem } from '../definitions';

export class TopNavigation {
    items: NavItem[];

    constructor(
        items
    ) {
        this.items = [...items];
    }
}
