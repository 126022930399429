import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {HeadTag} from '../definitions/data-store/seo';

@Injectable({
    providedIn: 'root'
})
export class SeoService {

    constructor(private titleService: Title, private metaService: Meta) {

    }

    updateTitle(title: string) {
        this.titleService.setTitle(title);
    }

    updateMeta(metaServices: HeadTag[]) {
        metaServices.forEach((item) => {
            if (item.attributes.tag === 'property') {
                this.metaService.updateTag({
                    property: item.attributes.name,
                    content: item.attributes.content
                });
            } else {
                this.metaService.updateTag({
                    name: item.attributes.name,
                    content: item.attributes.content
                });
            }
        });
    }
}

