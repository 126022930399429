import { HomePageService } from './../data-store/home-page.service';
import { HomepageResponse } from './../definitions/data-store/homepage-response';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HomePageResolverService implements Resolve<HomepageResponse> {

  constructor(private homePageService: HomePageService) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<any> {
      console.log('resolve');
      return this.homePageService.getPageData().pipe(take(1));
    }
}
