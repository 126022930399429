import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
    selector: 'app-mobile-menu-toggle',
    templateUrl: './mobile-menu-toggle.component.html',
    styleUrls: ['./mobile-menu-toggle.component.scss']
})
export class MobileMenuToggleComponent implements OnInit {

    @Input() isMobileMenuOpen = false;
    @Output() mobileMenuToggle = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnInit() {
    }

    toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
        this.mobileMenuToggle.emit(this.isMobileMenuOpen);
    }

}
