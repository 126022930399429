import { isPlatformBrowser } from '@angular/common';
import { trigger, transition, style, animate } from '@angular/animations';
import { Hero } from './../../../definitions/shared-components/hero';
import { Component, OnInit, Input, ViewEncapsulation, EventEmitter, Output, AfterContentInit, Inject, PLATFORM_ID } from '@angular/core';
import { GlobalEventBusService } from '../../../data-store/global-event-bus.service';


@Component({
  selector: 'app-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class HeroComponent implements OnInit, AfterContentInit {

  @Input() data: Hero;
  @Input() makeRequestForOffer: EventEmitter<boolean> = new EventEmitter();
  @Input() isMobile: boolean;
  @Output() load: EventEmitter<any> = new EventEmitter();

  get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  constructor(private globalEventBus: GlobalEventBusService, @Inject(PLATFORM_ID) private platformId) { }

  ngOnInit() {
    console.log('h', this.data);
  }

  requestOffer() {
    this.globalEventBus.openOfferBox({step: 0});
  }

  ngAfterContentInit() {
    this.load.emit(true);
  }
}
