<footer class="footer">
  <p>{{ data?.footer_text }}</p>
  <a [href]="data?.work_with_us_link">{{ data?.work_with_us_text }}</a>

  <div>{{ data?.first_menu_title }}</div>
  <ul>
    <li *ngFor="let item of data?.footer_menu_first">
      <a [href]="item.url">{{ item.name }}</a>
    </li>
  </ul>

  <div>{{ data?.second_menu_title }}</div>
  <ul>
    <li *ngFor="let item of data?.footer_menu_second">
      <a [href]="item.url">{{ item.name }}</a>
    </li>
  </ul>

  <div>{{ data?.third_menu_title }}</div>

  <ul>
    <li *ngFor="let item of data?.footer_menu_third">
      <a [href]="item.url">{{ item.name }}</a>
    </li>
  </ul>

  <div>{{ data?.fourth_menu_title }}</div>
  <address>
    <div [innerHtml]="data?.footer_contact"></div>
    <br />
    <a [href]="'tel:' + data?.footer_phone">{{ data?.footer_phone }}</a
    ><br />
    <a [href]="'mailto:' + data?.footer_email">{{ data?.footer_email }}</a>
  </address>

  <div>
    Copyright by Alphta Digital Lab
  </div>
  <div>Made with love in Berlin</div>
</footer>
