import { ScriptsLoaderService } from './shared/data-access/scripts-loader.service';
import { OfferPopupComponent as IOfferPopupComponent } from './shared/shared-components/offer-popup/offer-popup.component';
import { WithLazyComponents } from './shared/definitions/shared-components/with-lazy-components';
import { FooterData } from './shared/definitions/shared-components/footer-data';
import { HomepageHeroService } from './shared/data-store/homepage-hero.service';
import { Hero } from './shared/definitions/shared-components/hero';
import { GlobalEventBusService } from './shared/data-store/global-event-bus.service';
import { ConfigurationResponse, ConfigurationResponseFooterData } from './shared/definitions/data-access/configuration';
import { filter, map, tap, takeUntil } from 'rxjs/operators';
import { Router, RouterEvent, NavigationStart, NavigationEnd, ActivatedRoute } from '@angular/router';
import { HomePageService } from './shared/data-store/home-page.service';
import {
    Component,
    ViewEncapsulation,
    Inject,
    Renderer2,
    PLATFORM_ID,
    HostListener,
    AfterContentInit,
    ChangeDetectorRef,
    ViewChild,
    ViewContainerRef,
    ComponentRef,
    ComponentFactoryResolver,
    Injector,
    NgZone
} from '@angular/core';
import { ConfigurationService } from './shared/data-store/configuration.service';
import { Observable, BehaviorSubject, Subject, config, combineLatest } from 'rxjs';
import { NavItem } from './shared/definitions';
import { isPlatformBrowser, DOCUMENT, isPlatformServer } from '@angular/common';
import { BasePage } from './pages/base-page/base-page.class';
import { OfferPopupParams } from './shared/definitions/shared-components/offer-popup-form';
import { ComponentsBuilder } from './shared/utils/components-builder';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent extends BasePage implements AfterContentInit, WithLazyComponents {
    private clickedOutsideSubject: Subject<any> = new Subject();
    private popupVisible = false;
    private componentsRendered = false;
    private popupComponentInstanceRef: ComponentRef<IOfferPopupComponent>;
    private enableHotJar = false;

    @ViewChild('footerContainer', { read: ViewContainerRef, static: true }) footerContainer;
    @ViewChild('offerPopupContainer', { read: ViewContainerRef, static: true }) offerPopupContainer;


    topNavigation$: Observable<NavItem[]>;
    footerData$: Observable<ConfigurationResponseFooterData>;
    title = 'alphta-v2';
    offerPopupParams: OfferPopupParams = null;

    get showPopup() {
        return this.popupVisible;
    }

    get isServer() {
        return isPlatformServer(this.platformId);
    }

    loadingSubject: BehaviorSubject<any> = new BehaviorSubject(true);
    loading$: Observable<any> = this.loadingSubject.asObservable();

    showPopupSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
    showPopup$: Observable<boolean> = this.showPopupSubject.asObservable();
    closeSubNavBS: BehaviorSubject<any> = new BehaviorSubject(null);
    closeSubNav$: Observable<any>;
    clickOutside$: Observable<boolean> = this.clickedOutsideSubject.asObservable();

    isHomepage = true;
    isLandingPage = false;
    heroData$: Observable<Hero>;

    footerData: FooterData;

    lazyComponents = {
        footer: async () => {
            const { FooterComponent } = await import('./shared/shared-components/footer/footer.component');

            this.buildComponent<any>(FooterComponent, {
                data: this.footerData,
            }, this.footerContainer);
        },
    };

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;

        if (!targetElement.classList.contains('header-menu__link') &&
            !targetElement.classList.contains('header-menu__item') &&
            !targetElement.classList.contains('header-menu__link__icon')) {
            this.clickedOutsideSubject.next({
                clickedOutside: true
            });
        }
    }

    constructor(
        private configService: ConfigurationService,
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private router: Router,
        @Inject(PLATFORM_ID) protected platformId: any,
        private globalEventBus: GlobalEventBusService,
        private route: ActivatedRoute,
        private homepageHeroData: HomepageHeroService,
        private cdr: ChangeDetectorRef,
        private cfr: ComponentFactoryResolver,
        private injector: Injector,
        private scriptsLoader: ScriptsLoaderService,
        private ngZone: NgZone
    ) {

        // this.topNavigation$ = this.configService.getTopNavigation();
        // this.preload(['http://localhost:4200/assets/img/im_1.png', 'http://localhost:4200/assets/img/im_0.png'])
        super(platformId);

        this.ngZone.runOutsideAngular(() => {
            const config$ = this.configService.getConfiguration();
            this.heroData$ = this.homepageHeroData.getMockHeroData();
            this.topNavigation$ = config$.pipe(map((configRes: ConfigurationResponse) => configRes.top_menu));

            combineLatest(
                [config$.pipe(map((configRes: ConfigurationResponse) => configRes.footer_data)), this.globalEventBus.isUnderTheFold$])
                .pipe(takeUntil(this.destroyed$))
                .subscribe(([footerData, isUnderTheFOld]) => {
                    this.footerData = footerData;
                    if (isUnderTheFOld && !this.componentsRendered) {
                        this.renderLazyComponents();
                        this.componentsRendered = true;
                    }
                });

            this.closeSubNav$ = this.router.events
                .pipe(
                    filter(e => e instanceof NavigationStart),
                    map((v) => {
                        return { closeSubNav: v };
                    })
                );


            this.showPopup$
                .pipe(takeUntil(this.destroyed$))
                .subscribe(async (showPopup) => {
                    this.onShowPopup(showPopup);
                });

            this.router.events.pipe(takeUntil(this.destroyed$), filter(event => event instanceof NavigationStart)).subscribe(event => {
                this.loadingSubject.next(true);
                this.globalEventBus.updateIsWowInitialized(false);
            });

            this.router.events.pipe(takeUntil(this.destroyed$), filter(event => event instanceof NavigationEnd)).subscribe(event => {
                this.onNavigationEnd(event);
            });

            this.globalEventBus.openOfferBox$
                .pipe(takeUntil(this.destroyed$),
                    filter(v => v !== null))
                .subscribe((data: any) => {
                    this.offerPopupParams = data;
                    this.onToggleOfferBox(Boolean(data));
                });
        });

    }

    async onShowPopup(showPopup: boolean) {
        if (showPopup) {
            if (!this.popupComponentInstanceRef) {
                const { OfferPopupComponent } = await import('./shared/shared-components/offer-popup/offer-popup.component');

                const offerPopupComponentFactory = this.cfr.resolveComponentFactory(OfferPopupComponent);

                const comp: ComponentRef<IOfferPopupComponent> = this.offerPopupContainer.createComponent(
                    offerPopupComponentFactory, null, this.injector
                );

                comp.instance.params = this.offerPopupParams;

                this.popupComponentInstanceRef = comp;

                this.popupComponentInstanceRef.instance.close
                    .pipe(takeUntil(this.destroyed$))
                    .subscribe((closeEvent) => {
                        this.closePopup();
                    });
            }
        } else {
            if (this.popupComponentInstanceRef) {
                this.popupComponentInstanceRef.destroy();
                this.popupComponentInstanceRef = null;
            }
        }
    }

    onNavigationEnd(event: any) {
        // Reload WoW animations when done navigating to page,
        // but you are free to call it whenever/wherever you like
        if (this.route.snapshot.queryParams.action) {

            this.offerPopupParams = { step: 1, reason: this.route.snapshot.queryParams.reason };
            this.onToggleOfferBox(true);
        }
        const urlAfterRedirect = (event as NavigationEnd).urlAfterRedirects;

        if (urlAfterRedirect === '/wordpress-cdn' && urlAfterRedirect[1] !== '?') {
            this.isLandingPage = true;
            this.footerContainer.clear();
        } else {
            this.isLandingPage = false;
        }

        if (urlAfterRedirect !== '/' && urlAfterRedirect[1] !== '?') {
            this.isHomepage = false;

            if (isPlatformBrowser(this.platformId)) {
                // tslint:disable-next-line:no-string-literal
                if (!window['WOW']) {
                    this.scriptsLoader.loadLoadAsync('/assets/wow.min.js', (() => {
                        // tslint:disable-next-line:no-string-literal
                        new window['WOW']({ live: true }).init();
                        this.globalEventBus.updateIsWowInitialized(true);
                        this.loadingSubject.next(false);
                    }).bind(this));
                } else {
                    this.loadingSubject.next(false);
                }
            }

        } else {
            this.isHomepage = true;
            this.loadingSubject.next(false);
        }

        this.onActivate(null);

        if (isPlatformBrowser(this.platformId)) {
            // tslint:disable-next-line:no-string-literal
            if (window && window['WOW']) {
                // tslint:disable-next-line:no-string-literal
                new window['WOW']({ live: true }).init();
                this.globalEventBus.updateIsWowInitialized(true);
            }
        }
    }

    onHeroLoad() {
        this.loadingSubject.next(false);
        this.cdr.detectChanges();
    }

    onToggleOfferBox(event: boolean) {
        this.popupVisible = event;
        this.renderer.addClass(this.document.body, 'body--fixed');
        this.showPopupSubject.next(this.popupVisible);
    }

    closePopup() {
        this.popupVisible = false;
        this.renderer.removeClass(this.document.body, 'body--fixed');
        this.showPopupSubject.next(this.popupVisible);
    }

    onActivate(event: any) {
        if (isPlatformBrowser(this.platformId) && !this.route.snapshot.queryParams.filter && !this.route.snapshot.fragment) {
            window.scroll(0, 0);
        }
    }

    async renderLazyComponents() {
        ComponentsBuilder.renderLazyComponents(this.lazyComponents);
    }

    buildComponent<T>(componentClass, inputMap, viewContainer: ViewContainerRef) {
        viewContainer.clear();
        const compRef: ComponentRef<T> =
            ComponentsBuilder.buildComponent<T>(componentClass, inputMap, viewContainer, this.cfr) as ComponentRef<T>;
        // tslint:disable-next-line:no-string-literal
        compRef.instance['isMobile'] = this.isMobile;
    }

    // tslint:disable-next-line:use-life-cycle-interface
    ngAfterContentInit() {
        if (isPlatformBrowser(this.platformId)) {
            this.document.getElementsByTagName('body')[0].classList.remove('not-ready');
            if (this.document.location.host === 'alphta.de' || this.document.location.host.indexOf('127.0.0.1') > -1) {
                ((i, s, o, g, r, a, m) => {
                    /* tslint:disable:no-string-literal */
                    i['GoogleAnalyticsObject'] = r;
                    (i[r] =
                        i[r] ||
                        /* tslint:disable:only-arrow-functions */
                        function() {
                            (i[r].q = i[r].q || []).push(arguments);
                        }),
                        (i[r].l = 1 * new Date().getTime());
                    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
                    a.async = 1;
                    a.src = g;
                    m.parentNode.insertBefore(a, m);
                })(
                    window,
                    this.document,
                    'script',
                    'https://www.google-analytics.com/analytics.js',
                    'ga'
                );
                /* tslint:disable:no-string-literal */
                window['ga']('create', 'UA-77177849-1', 'auto');
                window['ga']('set', 'anonymizeIp', true);
                window['ga']('send', 'pageview');

                if (this.enableHotJar) {
                    ((h, o, t, j, a, r) => {
                        h.hj =
                            h.hj ||
                            /* tslint:disable:only-arrow-functions */
                            function() {
                                (h.hj.q = h.hj.q || []).push(arguments);
                            };
                        h._hjSettings = { hjid: 1574592, hjsv: 6 };
                        a = o.getElementsByTagName('head')[0];
                        r = o.createElement('script');
                        r.async = 1;
                        r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
                        a.appendChild(r);
                    })(window as any, this.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
                }

            }

        }
    }
}
