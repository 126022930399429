import { ComponentRef, ComponentFactoryResolver, ViewContainerRef } from '@angular/core';

export class ComponentsBuilder {
    static buildComponent<T>(
        componentClass: any,
        inputMap: { [key: string]: any },
        container: ViewContainerRef, resolver: ComponentFactoryResolver) {
        const ref = container.createComponent(
            resolver.resolveComponentFactory(componentClass)
        );

        Object.keys(inputMap).forEach((key) => {
            ref.instance[key] = inputMap[key];
        });

        return ref;
    }

    static async renderLazyComponents(lazyComponentsMap: any) {
        for (const key in lazyComponentsMap) {
            if (lazyComponentsMap[key]) {
                await lazyComponentsMap[key]();
            }
        }
    }
}
