import {HomepageResponse, HomepageSections} from './../definitions/data-store/homepage-response';
import {ApiService} from './../data-access/api.service';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap, tap, catchError} from 'rxjs/operators';
import { IndustryItem, Industries } from '../definitions/data-store/industries';
import { PageDataService } from './page-service-abstract.class';
import {SeoService} from '../utils/seo.service';

@Injectable({
    providedIn: 'root'
})
export class HomePageService extends PageDataService {

    constructor(private apiService: ApiService, private seoService: SeoService) {
        super();
    }

    getPageData(): Observable<HomepageResponse> {
        console.log('GetPageDAta');
        return this.apiService.getHomePageData().pipe(
            tap((data: HomepageResponse) => {
                this.setWidgetsData(data);
            }),
            catchError((err) => {
                return of(null);
            })
        );
    }

    setWidgetsData(data: HomepageResponse): void {
        this.seoService.updateTitle(data.seo.title);
        this.seoService.updateMeta(data.seo.headTags);

        const industries = ApiService.formatIndustries({...data.sections.industries});

        const homepageWidgets = [
            data.seo,
            data.sections.hero,
            data.sections.offer,
            data.sections.quote,
            data.sections.portfolio,
            data.sections.technologies,
            data.sections.agencyintro,
            data.sections.numbers,
            data.sections.userstories,
            industries,
            data.sections.ourphilosophy,
            data.sections.secondquote,
            data.sections.blogintro,
            data.sections.offerintro
        ];

        this.widgetsSubject.next(homepageWidgets);
    }

}
