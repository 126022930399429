import { FooterSsrComponent } from './shared/shared-components/footer-ssr/footer-ssr.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DISQUS_SHORTNAME } from 'ngx-disqus';
import { HomeContentSsrComponent } from './shared/shared-components/home-content-ssr/home-content-ssr.component';
import { SsrNoRenderDirective } from './shared/utils/ssr-no-render.directive';
import { API_URL, DEFAULT_PLACEHOLDER_IMAGE, ALPHTA_DEFAULT_SWIPER_CONFIG } from './shared/tokens/app-tokens';
import { OurOfferModule } from './shared/shared-components/our-offer/our-offer.module';
import { HeroModule } from './shared/shared-components/hero/hero.module';
import { HomePageResolverService } from './shared/data-access/home-page-resolver.service';
import { HomeComponent } from './pages/home/home/home.component';
import { HeaderModule } from './shared/shared-components/header/header.module';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule, InjectionToken } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserTransferStateModule, TransferState } from '@angular/platform-browser';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { LazyLoadImageModule, scrollPreset, intersectionObserverPreset } from 'ng-lazyload-image-angular-universal';

import { environment } from '../environments/environment';
import { CommonModule } from '@angular/common';
import { QuoteModule } from './shared/shared-components/quote/quote.module';

const DEFAULT_SWIPER_CONFIG: any = {
    observer: true,
    direction: 'horizontal',
    threshold: 10,
    spaceBetween: 10,
    slidesPerView: 1,
    centeredSlides: true,
    navigation: true,
    fadeEffect: {
        crossFade: true
    }
};

const apiUrl = environment.apiUrl;

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HomeContentSsrComponent,
        SsrNoRenderDirective,
        FooterSsrComponent
    ],
    imports: [
        CommonModule,
        BrowserModule.withServerTransition({ appId: 'my-app' }),
        BrowserTransferStateModule,
        BrowserAnimationsModule,
        HeaderModule,
        AppRoutingModule,
        HttpClientModule,
        LoadingBarHttpClientModule,
        LoadingBarRouterModule,
        HeroModule,
        OurOfferModule,
        ReactiveFormsModule,
        QuoteModule,
        ScrollToModule.forRoot(),
        LazyLoadImageModule.forRoot({
            preset: intersectionObserverPreset
        })
    ],
    exports: [AppRoutingModule],
    providers: [
        HomePageResolverService,
        {
            provide: API_URL,
            useValue: apiUrl
        },
        {
            provide: ALPHTA_DEFAULT_SWIPER_CONFIG,
            useValue: {
                observer: true,
                direction: 'horizontal',
                threshold: 10,
                spaceBetween: 10,
                slidesPerView: 1,
                centeredSlides: true,
                navigation: true,
                fadeEffect: {
                    crossFade: true
                }
            }
        },
        {
            provide: DEFAULT_PLACEHOLDER_IMAGE,
            useValue: 'https://cdn2.alphta.de/wp-content/uploads/2020/02/29120418/placeholder.png'
        },
        SsrNoRenderDirective,
        { provide: DISQUS_SHORTNAME, useValue: 'alphtade' },

    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
