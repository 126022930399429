import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeviceDetectorService {
  private platform = '';

  get isMac() {
    return /(Mac|iPhone|iPod|iPad)/i.test(this.platform);
  }

  get isIos() {
    return /(iPhone|iPod|iPad)/i.test(this.platform);
  }

  constructor(@Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.platform = window.navigator.platform;
    }
  }

}
