import { Component, OnInit, Input } from '@angular/core';
import { Offer } from '../../definitions/data-store/offer';
import { GlobalEventBusService } from '../../data-store/global-event-bus.service';
import { ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-our-offer',
    templateUrl: './our-offer.component.html',
    styleUrls: ['./our-offer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OurOfferComponent implements OnInit {
    @Input() data: Offer;

    constructor(private globalEventBus: GlobalEventBusService) {
    }

    openOfferBox(event: Event): void {
        event.preventDefault();
        this.globalEventBus.openOfferBox({
            step: 1,
            reason: 'homepage-technologies'
        });
    }

    ngOnInit() {
    }
}
