import { isPlatformServer } from '@angular/common';

import { BlogIntro } from './../../../shared/definitions/data-store/BlogIntro';
import { Philosophy } from './../../../shared/definitions/data-store/philosophy';
import { UserStories } from './../../../shared/definitions/data-store/user-stories';
import { Technologies } from './../../../shared/definitions/data-store/technologies';
import { Portfolio } from './../../../shared/definitions/data-store/homepage-portfolio';
import { Seo } from './../../../shared/definitions/data-store/seo';
import { Hero } from './../../../shared/definitions/shared-components/hero';
import { HomePageService } from './../../../shared/data-store/home-page.service';
import {
    Component, OnInit, ViewEncapsulation, PLATFORM_ID, Inject, ComponentRef, ViewContainerRef, ViewChild, ComponentFactoryResolver
} from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

import { filter, takeUntil, take } from 'rxjs/operators';
import { Offer } from '../../../shared/definitions/data-store/offer';
import { Quote } from '../../../shared/definitions/data-store/quote';
import { AgencyIntro } from '../../../shared/definitions/data-store/agencyIntro';
import { Numbers } from '../../../shared/definitions/data-store/numbers';
import { Industries } from '../../../shared/definitions/data-store/industries';

import { BasePage } from '../../base-page/base-page.class';
import { SecondQuote } from '../../../shared/definitions/data-store/second-quote';
import { OfferIntro } from '../../../shared/definitions/data-store/offer-intro';
import { GlobalEventBusService } from '../../../shared/data-store/global-event-bus.service';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent extends BasePage implements OnInit {

    private children: ComponentRef<any>[] = [];
    private content: any;
    @ViewChild('componentsContainer', { read: ViewContainerRef, static: true }) componentsContainer;


    heroData: Hero;
    seo: Seo;
    offerData: Offer;
    portfolioData: Portfolio;
    technologiesData: Technologies;
    quoteData: Quote;
    userStoriesData: UserStories;
    agencyIntro: AgencyIntro;
    numbersData: Numbers;
    industriesData: Industries;
    philosophyData: Philosophy;
    secondQuote: SecondQuote;
    blogIntroData: BlogIntro;
    offerIntro: OfferIntro;

    lazyComponentsContents = {
        portfolioData: null,
        technologiesData: null,
        quoteData: null,
        userStoriesData: null,
        agencyIntro: null,
        numbersData: null,
        industriesData: null,
        philosophyData: null,
        secondQuote: null,
        blogIntroData: null,
        offerIntro: null,
    };

    get isServer() {
        return isPlatformServer(this.platformId);
    }

    components = {
        ourTeam: async () => {
            const { OurTeamComponent } = await import('./../../../shared/shared-components/our-team/our-team.component');
            this.buildComponent(OurTeamComponent, this.lazyComponentsContents.agencyIntro);
        },
        numbers: async () => {
            const { NumbersComponent } = await import('./../../../shared/shared-components/numbers/numbers.component');
            this.buildComponent(NumbersComponent, this.lazyComponentsContents.numbersData);
        },
        industries: async () => {
            const { IndustriesComponent } = await import('./../../../shared/shared-components/industries/industries.component');
            this.buildComponent(IndustriesComponent, this.lazyComponentsContents.industriesData);
        },
        aboutOurOffer: async () => {
            // tslint:disable-next-line:max-line-length
            const { AboutOurOfferComponent } = await import('./../../../shared/shared-components/about-our-offer/about-our-offer.component');
            this.buildComponent(AboutOurOfferComponent, this.lazyComponentsContents.offerIntro);
        },
        ourProjects: async () => {
            const { OurProjectsComponent } = await import('./../../../shared/shared-components/our-projects/our-projects.component');
            this.buildComponent(OurProjectsComponent, this.lazyComponentsContents.portfolioData);
        },
        aboutOurProjects: async () => {
            // tslint:disable-next-line:max-line-length
            const { AboutOurProjectsComponent } = await import('./../../../shared/shared-components/about-our-projects/about-our-projects.component');
            this.buildComponent(AboutOurProjectsComponent, this.lazyComponentsContents.secondQuote);
        },
        ourPhilosophy: async () => {
            const { OurPhilosophyComponent } = await import('./../../../shared/shared-components/our-philosophy/our-philosophy.component');
            this.buildComponent(OurPhilosophyComponent, this.lazyComponentsContents.philosophyData);
        },
        technology: async () => {
            const { TechnologyComponent } = await import('./../../../shared/shared-components/technology/technology.component');
            this.buildComponent(TechnologyComponent, this.lazyComponentsContents.technologiesData);
        },
        blogListing: async () => {
            const { BlogListingComponent } = await import('./../../../shared/shared-components/blog/blog-listing.component');
            this.buildComponent(BlogListingComponent, this.lazyComponentsContents.blogIntroData);
        },
        references: async () => {
            const { ReferencesComponent } = await import('./../../../shared/shared-components/references/references.component');
            this.buildComponent(ReferencesComponent, this.lazyComponentsContents.userStoriesData);
        },
        quickContact: async () => {
            const { QuickContactComponent } = await import('./../../../shared/shared-components/quick-contact/quick-contact.component');
            this.buildComponent(QuickContactComponent, null);
        }
    };

    rendered = false;

    constructor(
        private homePage: HomePageService,
        private meta: Meta,
        private title: Title,
        @Inject(PLATFORM_ID) protected platformId: any,
        private cfr: ComponentFactoryResolver,
        private globalEventsBus: GlobalEventBusService
    ) {
        super(platformId);
        this.homePage
            .getPageData()
            .pipe(
                takeUntil(this.destroyed$)
            )
            .subscribe();
    }

    ngOnInit() {
        combineLatest([this.homePage.widgetsData$
            .pipe(
                takeUntil(this.destroyed$),
                filter(d => d !== null)
            ), this.globalEventsBus.isUnderTheFold$.pipe(
                takeUntil(this.destroyed$)
            )]).subscribe(([widgetsData, isUnderTheFold]) => {
                const [
                    seo,
                    heroData,
                    offer,
                    quote,
                    portfolio,
                    technologies,
                    agencyIntro,
                    numbers,
                    userStories,
                    industries,
                    philosophy,
                    secondQuote,
                    blogIntro,
                    offerIntro
                ] = widgetsData;

                this.heroData = heroData;
                this.seo = seo;
                this.offerData = offer;
                this.quoteData = quote;
                this.lazyComponentsContents.portfolioData = portfolio;
                this.lazyComponentsContents.technologiesData = technologies;
                this.lazyComponentsContents.userStoriesData = userStories;
                this.lazyComponentsContents.agencyIntro = agencyIntro;
                this.lazyComponentsContents.technologiesData = technologies;
                this.lazyComponentsContents.numbersData = numbers;
                this.lazyComponentsContents.industriesData = industries;
                this.lazyComponentsContents.philosophyData = philosophy;
                this.lazyComponentsContents.secondQuote = secondQuote;
                this.lazyComponentsContents.blogIntroData = blogIntro;
                this.lazyComponentsContents.offerIntro = offerIntro;

                if (isUnderTheFold && !this.rendered) {
                    this.renderComponents();
                    this.rendered = true;
                }

                this.title.setTitle(this.seo.title);
            });

    }

    async renderComponents() {
        console.log('Render', this);
        // this.children.forEach(cmp => cmp.destroy());
        this.children = [];
        for (const key in this.components) {
            if (this.components[key]) {
                await this.components[key]();
            }
        }
    }

    buildComponent(componentClass: any, input: any) {
        const ref = this.componentsContainer.createComponent(
            this.cfr.resolveComponentFactory(componentClass)
        );
        ref.instance.data = input;
        ref.instance.isMobile = this.isMobile;
        this.children.push(ref);
    }

}
