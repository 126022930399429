import { PipesModule } from '../../utils/pipes/pipes.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { HeaderMenuComponent } from './header-menu/header-menu.component';
import { RouterModule } from '@angular/router';
import { MobileMenuToggleComponent } from './mobile-menu-toggle/mobile-menu-toggle.component';

@NgModule({
  declarations: [HeaderComponent, HeaderMenuComponent, MobileMenuToggleComponent],
  imports: [
    CommonModule,
    RouterModule,
    PipesModule
  ],
  exports: [
    HeaderComponent,
    HeaderMenuComponent
  ]
})
export class HeaderModule { }


